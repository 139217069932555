// 3rd party
import React from "react"
import styled from "styled-components"

// constants
import {colors} from "../../../constants/css"

function WorkContentControls({selectedItem, content, handleItemClick}) {
  return (
    <Dots>
      {content.length > 1 &&
        content.map((item, i) => {
          return (
            <Dot key={i} onClick={() => handleItemClick(i)}>
              <DotInner isActive={selectedItem === i} />
            </Dot>
          )
        })}
    </Dots>
  )
}

const Dots = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  height: 26px;
  z-index: 2;
  align-items: center;
`

const Dot = styled.button`
  width: 18px;
  height: 20px;
  padding: 5px 4px;
`

const DotInner = styled.div`
  background-color: ${({isActive}) => (isActive ? colors.red : colors.white)};
  border-radius: 50%;
  width: 100%;
  height: 100%;
`

export default WorkContentControls
