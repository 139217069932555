import {distinct, getWorkUri, stripNode} from "./utils"

// attached in menu
export function getAttached(post, navData, navType) {
  // find all works that are attached to current project/medium
  let attached = navData.works.filter(({node: work}) => {
    // work[navType] results in array of related mediums or projects depending on menu state
    return work[navType].nodes.some(el => el.id === post.id)
  })
  attached = stripNode(attached)
  // convert array of attached works to desired form depending on menu state
  if (navType === "medium") {
    attached = getAttachedForMedium(attached, navData.project, post)
  } else if (navType === "project") {
    attached = getAttachedForProject(attached, navData.medium, post)
  }
  return attached
}

function getItemsFromWorks(works, type, items) {
  // get array of items of type (either medium or project) that have input works attached to them

  // get array of item ids of input works
  let ids = []
  works.forEach((work, i) => {
    work[type].nodes.forEach(item => {
      ids.push(item.id)
    })
  })
  // find all items that input works are attached to
  let filteredItems = items.filter(({node}) => ids.some(id => id === node.id))
  filteredItems = stripNode(filteredItems)
  return filteredItems
}
function getMainItem(items, item) {
  // if item (project or medium) has a parent, find the parent project
  let mainItem = []
  if (item) {
    mainItem = items.filter(({node}) => {
      return node.id === item.parent?.node.id
    })
  }
  return !!mainItem.length ? mainItem[0].node : null
}

function getMainProjectsFromProjects(filteredProjects, projects) {
  // input: array of projects, output: array of only main projects.
  let mainProjects = []
  filteredProjects.forEach((project, i) => {
    if (project.parent) {
      let mainProject = getMainItem(projects, project)
      mainProject && mainProjects.push(mainProject)
    } else {
      // if the project is a main project, add that main project
      mainProjects.push(project)
    }
  })
  mainProjects = distinct(mainProjects)
  return mainProjects
}

function getWorksBelowProject(works, filteredProjects, mainProject) {
  // get all works attached to this project or to a project that is a subproject of this project
  let worksBelowProject = works.filter(work => {
    let projectsOfWork = filteredProjects.filter(project => work.project.nodes.some(e => e.id === project.id))
    return (
      projectsOfWork?.some(project => project.parent?.node.id === mainProject.id) ||
      work.project.nodes.some(e => e.id === mainProject.id)
    )
  })
  return worksBelowProject
}

function getAttachedForMedium(works, projects, post) {
  let filteredProjects = getItemsFromWorks(works, "project", projects)
  let mainProjects = getMainProjectsFromProjects(filteredProjects, projects)
  let attached = []
  mainProjects.forEach((mainProject, i) => {
    let worksBelowProject = getWorksBelowProject(works, filteredProjects, mainProject)
    attached.push({
      ...mainProject,
      image: worksBelowProject[0].image,
      uri: `${post.uri}?p=${mainProject.id}&t=medium`
    })
  })
  return attached
}

function getAttachedForProject(works, mediums, post) {
  let attached = []
  works.forEach(work => {
    let medium = getItemsFromWorks([work], "medium", mediums)[0]
    let mainMedium = getMainItem(mediums, medium)
    attached.push({
      ...work,
      uri: getWorkUri(work, medium?.id, post.id, "project"),
      subtitle: (mainMedium ? mainMedium?.medium.singular + " - " : "") + medium?.medium.singular
    })
  })
  return attached
}

// attached on page
function getAttachedWorksByMenuType(works, navType, page) {
  let items = works.filter(work => work.node[navType]?.nodes.some(e => e.id === page.sub[navType]))
  return items
}

function getMediumOrProject(navData, type, item) {
  let mediumOrProject = navData[type].filter(navItem => item[type].nodes.some(e => e.id === navItem.node.id))
  mediumOrProject = !!mediumOrProject.length && mediumOrProject[0].node
  return mediumOrProject
}

export function getNavItem(id, type, navData) {
  if (id) {
    let navItems = navData[type].filter(({node}) => node.id.toString() === id.toString())
    return !!navItems.length ? navItems[0].node : null
  }
}

function getAttachedItem(navData, type, byType, mediumId, projectId, /* pageType,*/ item, page) {
  let child = page[type]
  let parent = navData[type].filter(proj => proj.node.id === child.parent?.node.id)
  parent = !!parent.length && parent[0].node
  //let baseUri = page[byType].uri
  return {
    ...item,
    subtitle: (parent ? parent.title + " - " : "") + child.title,
    uri: getWorkUri(item, mediumId, projectId, byType)
  }
}

export function getAttachedToPage(navData, navType, page) {
  let items = getAttachedWorksByMenuType(navData.works, navType, page)
  items.forEach(({node: item}, i) => {
    let project = getMediumOrProject(navData, "project", item)
    let medium = getMediumOrProject(navData, "medium", item)
    let byType = navType === "project" ? "medium" : "project"
    let pageData = {...{project, medium}}
    items[i] = getAttachedItem(navData, byType, navType, medium.id, project.id, item, pageData)
  })
  return items
}

let imageSizes = ["thumb-small", "thumbnail", "thumb-large", "small", "medium", "medium_large", "large", "extra-large"]

export function getImage(image, sizeName) {
  if (!!image?.mediaDetails.sizes?.length) {
    let imageSizeIndex = imageSizes.indexOf(sizeName)
    let filteredImages = image.mediaDetails.sizes?.filter(el => el.name === sizeName)
    if (!filteredImages.length) {
      // try a smaller image size if desired one is unavailable
      filteredImages = image.mediaDetails.sizes?.filter(el => el.name === imageSizes[imageSizeIndex - 1])
    }
    return !!filteredImages.length ? filteredImages[0] : null
  }
}
