// 3rd party
import React from "react"
import styled from "styled-components"

// constants
import {colors, sizes} from "../../constants/css"

// compoments
import NavLinkWrapper from "../nav/navLinkWrapper"
import Diamond from "../icons/diamond"
import FadeInImage from "../general/fadeInImage"
import Link from "../general/link"
import {getImage} from "../../lib/dataManipulation"

let diamondWidth = 22

const AttachedWorkItem = ({uri, img, title, subtitle, active}) => {
  let image = getImage(img, "thumb-small")
  return (
    <NavLinkWrapper>
      <Link uri={uri}>
        <ImgWrapper active={active}>
          <Overlay $active={active} />
          {active && (
            <Center>
              <DiamondWrapper>
                <Diamond />
              </DiamondWrapper>
            </Center>
          )}
          {image?.sourceUrl && <FadeInImage src={image.sourceUrl} />}
        </ImgWrapper>
        <TextWrapper bgCol="red">{title && <Header dangerouslySetInnerHTML={{__html: title}} />}</TextWrapper>
        <TextWrapper bgCol="black">{subtitle !== "undefined" && <h5>{subtitle}</h5>}</TextWrapper>
      </Link>
    </NavLinkWrapper>
  )
}

const Center = styled.div`
  width: ${diamondWidth}px;
  //height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const Overlay = styled.div`
  background-color: ${({active, bgCol}) => colors.black};
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: ${({$active}) => ($active ? 0.6 : 0)};
  top: 0;
`

const TextWrapper = styled.div`
  position: relative;
  background-color: ${({active, bgCol}) => colors[bgCol]};
  text-align: center;
  padding: 2px;
`

const Header = styled.h5`
  display: inline;
`

const ImgWrapper = styled.div`
  background-color: ${colors.black};
  position: relative;
  margin: 15px auto 8px auto;

  width: ${sizes.attachedWidth}px;
  height: ${sizes.attachedWidth}px;
  border-radius: 50%;
  border: 7px solid ${({active}) => (active ? "transparent" : colors.red)};
  overflow: hidden;
`

const DiamondWrapper = styled.div`
  width: ${diamondWidth}px;
  svg {
    * {
      fill: ${colors.white};
    }
  }
  //position: absolute;
  display: inline-block;
  //left: 5px;
  //top: 7px;
`

export default AttachedWorkItem
