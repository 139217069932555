//3rd party
import React from "react"
import styled from "styled-components"

// components
import Medium from "../icons/medium"
import Project from "../icons/project"
import Work from "../icons/work"

function BelowMenuTitle({text}) {
  return (
    <Title aria-label={text}>
      {text === "medium" && <Medium />}
      {text === "project" && <Project />}
      {text === "work" && <Work />}
    </Title>
  )
}

const Title = styled.h3``
export default BelowMenuTitle
