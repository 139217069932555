// 3rd party
import React from "react"

// utils
import {splitText} from "../../../lib/utils"

// components
import WpContent from "../../general/wpContent"
import WorkContentWrapper from "./workContentWrapper"
import Line from "../styled/line"
import TwoCol from "../styled/twoCol"
import styled from "styled-components"
import OneCol from "../styled/oneCol"

function WorkContentDescription({title, setIsModalOpen, content}) {
  let [firstHalf, secondHalf] = splitText(content)
  return (
    <WorkContentWrapper subtitle="info" title={title || "quotes"} {...{setIsModalOpen}}>
      <OneCol isHalf={!secondHalf}>
        {!secondHalf && (
          <Content>
            <WpContent content={firstHalf} />
          </Content>
        )}
        {!!secondHalf && (
          <TwoCol>
            <Content>
              <WpContent content={firstHalf} />
            </Content>
            <Line />
            <Content>
              <WpContent content={secondHalf} />
            </Content>
          </TwoCol>
        )}
      </OneCol>
    </WorkContentWrapper>
  )
}

const Content = styled.div`
  text-align: justify;
`
export default WorkContentDescription
