// 3rd party
import React from "react"
import styled from "styled-components"
import ReactPlayer from "react-player"

// constants
import EmbedAudio from "./embedAudio"
import EmbedVideo from "./embedVideo"
import Spinner from "../general/spinner"

function Embed({embed, autoplay = true, isLarge = true}) {
  let audio
  let video
  let isVideo = embed.includes("youtube.com") || embed.includes("vimeo.com")
  let isAudio = embed.includes("spotify.com") || embed.includes("bandcamp.com")
  if (embed) {
    if (isVideo) {
      video = embed
      video = video.split("&")[0] // remove start time from youtube
    }
    if (isAudio) {
      audio = embed
    }
  }

  let reactAudioPlayerProps = {url: audio, playing: autoplay, width: "100%", height: "100%"}

  return (
    embed && (
      <>
        <Center>
          <div className="relative">
            {/*<P>loading '{embed}'...</P>*/}
            <Spinner />
          </div>
        </Center>
        {video && <EmbedVideo {...{autoplay, video}} />}
        {audio && <EmbedAudio {...{autoplay, isLarge, audio}} />}
        {!audio && !video && <ReactPlayer url={embed} props={reactAudioPlayerProps} />}
      </>
    )
  )
}
/*
const P = styled.p`
  position: absolute;
  max-width: 170px;
  word-break: break-all;
`*/

const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
`

export default Embed
