// 3rd party
import React, {useEffect, useContext, useRef, useState} from "react"
import styled from "styled-components"

// constants
import {sizes, device, colors} from "../../constants/css"

// components
import AttachedWorkItem from "./attachedWorkItem"
import Scrollable from "../general/scrollable"
import {GlobalStateContext} from "../../context/GlobalContextProvider"

const AttachedWork = ({attached, inNav = false, firstId}) => {
  let appState = useContext(GlobalStateContext)
  attached = attached?.sort((a, b) => new Date(b.date) - new Date(a.date))
  attached.sort((a, b) => (a.id === firstId ? -1 : b.id === firstId ? 1 : 0))
  let [canScrollUp, setCanScrollUp] = useState(false)
  let [canScrollDown, setCanScrollDown] = useState(false)
  const myRef = useRef()
  function handleScroll() {
    if (myRef.current) {
      const bottom = myRef.current.scrollHeight - myRef.current.scrollTop === myRef.current.clientHeight
      const top = myRef.current.scrollTop === 0
      setCanScrollUp(!top ? true : false)
      setCanScrollDown(!bottom ? true : false)
    }
  }
  let canScroll = canScrollUp || canScrollDown
  useEffect(() => {
    setTimeout(() => handleScroll(), 100)
  }, [appState.navType])

  function handleClick(direction) {
    myRef.current.scrollBy({top: 300 * (direction === "down" ? 1 : -1), left: 0, behavior: "smooth"})
  }

  return (
    <AttachedWrapper {...{inNav, canScroll}}>
      {canScroll && (
        <button onClick={() => handleClick("up")}>
          <ArrowUp isVisible={canScrollUp} />
        </button>
      )}
      <NoOverflow>
        <Scrollable handleScroll={() => handleScroll()} {...{myRef}}>
          {!!attached.length && (
            <AttachedList>
              {attached.map(post => {
                const active = post.id === appState.page.currentId
                let image = post.image?.node
                let {title, subtitle} = post
                return (
                  <li key={post.id}>
                    <AttachedWorkItem {...{active, inNav, title, subtitle}} uri={post.uri} img={image} />
                  </li>
                )
              })}
            </AttachedList>
          )}
        </Scrollable>
      </NoOverflow>
      {(canScrollUp || canScrollDown) && (
        <button onClick={() => handleClick("down")}>
          <ArrowDown isVisible={canScrollDown} />
        </button>
      )}
    </AttachedWrapper>
  )
}
const Arrow = styled.div`
  left: 50%;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  transition: 0.2s;
  opacity: ${({isVisible}) => (isVisible ? 1 : 0.5)};
`
const ArrowUp = styled(Arrow)`
  border-bottom: 12px solid ${colors.white};
  top: 5px;
  transform: translateX(-50%);
`

const ArrowDown = styled(Arrow)`
  border-top: 12px solid ${colors.white};
  bottom: 5px;
  transform: translateX(-50%);
`
const NoOverflow = styled.div`
  overflow: hidden;
  height: 100%;
`
const AttachedWrapper = styled.div`
  a {
    text-decoration: none;
  }
  display: none;
  visibility: hidden;
  position: relative;
  width: ${sizes.attachedWidth}px;
  right: ${({inNav}) => (inNav ? -sizes.attachedWidth : sizes.attachedOffset)}px;
  @media ${device.tabletM} {
    display: ${({inNav}) => (inNav ? "none" : "block")};
    visibility: ${({inNav}) => (inNav ? "hidden" : "visible")};
  }
  @media ${device.laptopL} {
    display: block;
    visibility: visible;
  }

  position: ${({inNav}) => (inNav ? "absolute" : "fixed")};
  top: ${({inNav}) => (inNav ? 0 : 17.5)}%;
  bottom: ${({inNav}) => (inNav ? 0 : 17.5)}%;
  padding: ${({canScroll}) => (canScroll ? 35 : 15)}px 0;

  background-color: ${({inNav}) => (inNav ? colors.background : "transparent")};
`

const AttachedList = styled.ul`
  display: grid;
  margin-top: -15px;
  grid-row-gap: 25px;

    grid-auto-rows: min-content;
    grid-auto-columns: auto;
    grid-auto-flow: row;
  }
`

export default AttachedWork
