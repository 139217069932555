// 3rd party
import styled from "styled-components"

// constants
import {colors, device} from "../../../constants/css"

const Line = styled.div`
  height: 8px;
  @media ${device.mobileL} {
    height: 100%;
    width: 1px;
    margin: 0 10px;
    background-color: ${colors.white};
  }
`

export default Line
