// 3rd party
import styled from "styled-components"

// constants
import {device} from "../../../constants/css"

const TwoCol = styled.div`
  display: grid;
  width: 100%;
  position: relative;
  @media ${device.mobileL} {
    grid-template-columns: ${({isFirst = true}) => !isFirst && "auto"} minmax(0, 1fr) auto minmax(0, 1fr);
  }
`

export default TwoCol
