// 3rd party
import React from "react"
import styled from "styled-components"
import ReactPlayer from "react-player"

// components
import {getVimeoSourceUrl} from "../../lib/embeds"

function EmbedVideo({video, autoplay}) {
  //console.log(video, autoplay)

  let vimeoUrl = getVimeoSourceUrl(video)

  let reactVideoConfig = {
    youtube: {
      playerVars: {controls: 1}
    },
    vimeo: {
      playerVars: {controls: 1, autoplay: 1}
    }
  }

  return (
    <Content>
      {vimeoUrl ? (
        <iframe
          src={vimeoUrl}
          width="100%"
          height="100%"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          autoPlay
          title="0"
          color="ff0000"
        />
      ) : (
        <ReactPlayer url={video} playing={autoplay} width={"100%"} height={"100%"} config={reactVideoConfig} />
      )}
    </Content>
  )
}

const Content = styled.div`
  width: 100%;
  height: 100%;
  min-height: 300px;
`

export default EmbedVideo
