// 3rd party
import React, {useContext} from "react"
import styled from "styled-components"

// constants
import {colors, device, sizes, transition} from "../../constants/css"

// context
import {GlobalStateContext} from "../../context/GlobalContextProvider"
import {NavStateContext, NavDispatchContext} from "../../context/NavContextProvider"

// components
import Link from "../general/link"
import LinkText from "./linkText"
import Diamond from "../icons/diamond"
import NavLinkWrapper from "./navLinkWrapper"
import NavPreviewRound from "./navPreviewRound"

const NavSubItem = ({post, hasNoChildren}) => {
  // state
  const appState = useContext(GlobalStateContext)
  const navDispatch = useContext(NavDispatchContext)
  const navState = useContext(NavStateContext)

  // data
  const image = post.image.node //post.mainSub?.mainSub ? post.mainSub.mainSub[0]?.image?.node : post.image.node
  const active = navState.sub[appState.navType] === post.id

  // functions
  const onHover = () =>
    !appState.navIsCollapsed && !navState.isFrozen && !hasNoChildren && navDispatch({type: "setNavSub", id: post.id})

  return (
    <Li key={post.id} $hidden={hasNoChildren && appState.navIsCollapsed}>
      <NavLinkWrapper>
        <Link uri={post.uri}>
          <StyledLink
            onMouseOver={onHover}
            onFocus={onHover}
            onClick={e => appState.navIsCollapsed && e.preventDefault()}
            selected={active}
            hasNoChildren={hasNoChildren}
            collapsed={appState.navIsCollapsed}
            bgcol={active && !appState.navIsCollapsed ? colors.black : colors.red}
          >
            {!appState.navIsCollapsed && (
              <LinkText text={!hasNoChildren ? post.title : ""} active={active} dataUri={post.uri} dataId={post.id} />
            )}
          </StyledLink>
        </Link>
      </NavLinkWrapper>

      {active && (
        <DiamondWrapper
          canAnimate={hasNoChildren}
          top="3px"
          right={navState.previewPost && !appState.navIsCollapsed ? -16 : 0}
          translate={appState.navIsCollapsed ? 14 : 0}
        >
          <Diamond />
        </DiamondWrapper>
      )}
      <NavPreviewRound
        collapsed={appState.navIsCollapsed}
        visible={active}
        isPreviewOpen={!!navState.previewPost}
        {...{post, image}}
      />
    </Li>
  )
}

const DiamondWrapper = styled.div`
  @keyframes shift-in {
    from {
      right: calc(100% + 20px + ${({right}) => right}px);
    }

    to {
      right: ${({right}) => right}px;
    }
  }
  animation-name: ${({canAnimate}) => canAnimate && "shift-in"};
  animation-duration: 600ms;
  animation-fill-mode: forwards;
  width: ${sizes.navDiamondWidth}px;
  position: absolute;
  top: ${({top}) => top};
  right: ${({right}) => right}px;
  transform: translateX(${({translate}) => translate}px);
  transition: all ${transition.fast}ms;
`

const Li = styled.li`
  margin-bottom: 5px;
  position: relative;
  opacity: ${({$hidden}) => ($hidden ? 0 : 1)};
  transition: all ${transition.nav}ms;
`

const StyledLink = styled.div`
  white-space: nowrap;
  transition: color 0.2s;
  overflow: hidden;
  min-height: 1.125rem;

  display: block;
  text-transform: ${({selected}) => selected && "uppercase"};
  background-color: ${({selected, collapsed, hasNoChildren}) =>
    (selected && !collapsed) || (hasNoChildren && collapsed) ? colors.black : colors.red};
  padding-left: 7px;
  padding-right: ${({collapsed}) => (collapsed ? 0 : 7 + "px")};

  font-size: 0.625rem;
  @media ${device.tabletM} {
    font-size: 0.75rem;
  }
`

export default NavSubItem
