// 3rd party
import React, {useState, useRef, useEffect} from "react"
import styled from "styled-components"

// constants
import {transition} from "../../constants/css"

function FadeInImage({src, width, srcSet, sizes, aspectRatio, ...props}) {
  const imageRef = useRef()
  let [isVisible, setIsVisible] = useState(false)
  const onLoadCallBack = () => setIsVisible(true)

  useEffect(() => {
    // https://stackoverflow.com/questions/59787642/nextjs-images-loaded-from-cache-dont-trigger-the-onload-event
    if (imageRef.current.complete) onLoadCallBack()
  }, [])

  return (
    <StyledImage
      $isVisible={isVisible}
      ref={imageRef}
      onLoad={onLoadCallBack}
      {...props}
      src={src}
      srcSet={srcSet}
      sizes={sizes}
      alt=""
      width={width || "100%"}
      $aspectRatio={aspectRatio}
    />
  )
}

const StyledImage = styled.img`
  transition: opacity ${transition.gallery}ms;
  opacity: ${({$isVisible}) => ($isVisible ? 1 : 0)};
  height: auto;
  aspect-ratio: ${({$aspectRatio}) => $aspectRatio};
`

export default FadeInImage
