import React from "react"
import styled from "styled-components"
import {colors} from "../../constants/css"
const Arrow = ({direction, color = colors.red}) => {
  return (
    <Svg
      color={color}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 29.4 31.2"
      style={{enableBackground: "new 0 0 29.4 31.2"}}
      invert={direction === "left"}
    >
      <g>
        <path className="st0" d="M8.3,15.6C8.3,9.1,5,3.4,0,0l29.4,15.6L0,31.2C5,27.8,8.3,22.1,8.3,15.6" />
      </g>
    </Svg>
  )
}

const Svg = styled.svg`
  height: 100%;
  fill: ${({color}) => color};
  transform: scaleX(${({invert}) => (invert ? -1 : 1)});
`

export default Arrow
