// 3rd party
import React from "react"
import styled, {css} from "styled-components"

// general
import FadeInImage from "../../general/fadeInImage"
import {colors} from "../../../constants/css"
import Play from "../../icons/play"

function WorkThumbContent({image, isImage, size}) {
  return (
    <Wrapper hasBorder={!isImage}>
      {image?.sourceUrl && <FadeInImage src={image?.sourceUrl} />}
      {!isImage && (
        <Inner>
          <Play />
        </Inner>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  ${({hasBorder}) =>
    hasBorder &&
    css`
      border: 2px solid ${colors.white};
    `}
`

const Inner = styled.div`
  position: absolute;
  width: 85%;
  height: 85%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export default WorkThumbContent
