// 3rd party
import React from "react"
import styled, {css} from "styled-components"

// constants
import {device, sizes} from "../../constants/css"

function ArchiveList({children, hasLargeFirst = false}) {
  return <Ul hasLargeFirst={hasLargeFirst}>{children}</Ul>
}

// credits https://stackoverflow.com/questions/38351887/large-first-item-with-a-flexbox-layout

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  ${({hasLargeFirst}) =>
    hasLargeFirst &&
    css`
      li {
        &:first-child {
          width: 100%;
        }
      }
      @media ${device.mobileL} {
        grid-template-rows: repeat(auto-fill, ${sizes.archive.medium}px);
        display: grid;
        grid-gap: 0;
        grid-template-columns: ${sizes.archive.medium}px ${sizes.archive.medium}px repeat(auto-fill, ${sizes.archive.medium}px);

        li {
          &:first-child {
            grid-column: 1 / 3;
            grid-row: 1 / 3;
          }
        }
      }
      @media ${device.laptop} {
        grid-template-columns: ${sizes.archive.large}px ${sizes.archive.large}px repeat(auto-fill, ${sizes.archive.large}px);
        grid-template-rows: repeat(auto-fill, ${sizes.archive.large}px);
      }
    `}
`

export default ArchiveList
