//import {MenuDispatchContext} from "context/MenuContextProvider"
import React, {useContext} from "react"
import styled from "styled-components"
import {GlobalStateContext, GlobalDispatchContext} from "../../../context/GlobalContextProvider"

function SearchInput({inputRef, setHasBeenFocussed}) {
  const appState = useContext(GlobalStateContext)
  const appDispatch = useContext(GlobalDispatchContext)

  let handleChange = e => {
    appDispatch({type: "setSearchQuery", value: e.target.value})
    appDispatch({type: "setSearchIsActive", value: true})
    appDispatch({type: "setIsInfoOpen", value: false})
  }
  let handleFocus = () => {
    setHasBeenFocussed(true)
    appDispatch({type: "setSearchIsActive", value: true})
    appDispatch({type: "setIsInfoOpen", value: false})
  }
  return (
    <Input
      className="noDrag"
      onChange={handleChange}
      onFocus={handleFocus}
      //onTouchEnd={() => inputRef.current.focus()}
      aria-label="search input"
      value={appState.searchQuery}
      ref={inputRef}
    />
  )
}

const Input = styled.input`
  font-size: 0.75rem;
  height: 23px;
  display: block;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 2px;
`

export default SearchInput
