// 3rd party
import React, {useState, useRef, useEffect} from "react"
import styled from "styled-components"

// styled & libs
import Line from "../styled/line"
import OneCol from "../styled/oneCol"
import {getImage} from "../../../lib/dataManipulation"
import {getAspectRatio} from "../../splash/utils/getAspectRatio"

// components
import Embed from "../embed"
import WpContent from "../../general/wpContent"
import WorkContentWrapper from "./workContentWrapper"
import WorkContentControls from "./workContentControls"
import FadeInImage from "../../general/fadeInImage"
import Arrow from "../../icons/arrow"
import Spinner from "../../general/spinner"

function WorkContentOther({content, title, setIsModalOpen, activeItem}) {
  let [selectedItem, setSelectedItem] = useState(0)

  let contentRefs = useRef([])
  let scrollRef = useRef()

  console.log(content)
  useEffect(() => (contentRefs.current = contentRefs.current.slice(0, content?.length)), [content])

  function handleItemClick(i) {
    scrollRef.current.scrollTo({left: contentRefs.current[i].offsetLeft, behavior: "smooth"})
  }

  function handleArrowClick(increment) {
    let nextRef = contentRefs.current[selectedItem + increment]
    nextRef && scrollRef.current.scrollTo({left: nextRef.offsetLeft, behavior: "smooth"})
  }

  function handleScroll() {
    let minOffset = 99999
    let current = 0
    contentRefs.current.forEach((ref, i) => {
      if (scrollRef.current) {
        let offset = Math.abs(scrollRef.current.scrollLeft - ref.offsetLeft)
        if (offset < minOffset) {
          minOffset = offset
          current = i
        }
      }
    })
    setSelectedItem(current)
  }

  return (
    <WorkContentWrapper subtitle={activeItem.replace(/-/g, " ")} {...{title, setIsModalOpen}}>
      <OneCol>
        <FlexWrapper className="hide-scrollbar" ref={scrollRef} onScroll={handleScroll}>
          {content.map((contentItem, i) => {
            let image = getImage(contentItem.image, "medium")
            let isFirst = i === 0
            //let isActive = i === selectedItem
            return (
              <Wrapper
                isFirst={isFirst}
                hasMore={content.length > 1}
                ref={el => (contentRefs.current[i] = el)}
                key={i}
                name={`content-${i}`}
              >
                {!isFirst && <Line />}

                {contentItem.embed && (
                  <div className="relative">
                    <Embed embed={contentItem.embed} autoplay={i === 0} />{" "}
                  </div>
                )}
                {image?.sourceUrl && (
                  <ImageContainer>
                    <FadeInImage
                      src={image.sourceUrl}
                      aspectRatio={getAspectRatio(contentItem.image.mediaDetails)}
                      style={{zIndex: 2, position: "relative"}}
                    />
                    <Center>
                      <div className="relative">
                        <Spinner />
                      </div>
                    </Center>
                  </ImageContainer>
                )}
                <Line />
                <Text>{contentItem.description && <WpContent content={contentItem.description} />}</Text>
              </Wrapper>
            )
          })}

          <ArrowWrapperLeft onClick={() => handleArrowClick(-1)} isVisible={selectedItem > 0}>
            <Arrow direction="left" />
          </ArrowWrapperLeft>

          <ArrowWrapperRight onClick={() => handleArrowClick(1)} isVisible={selectedItem + 1 < content.length}>
            <Arrow direction="right" />
          </ArrowWrapperRight>
        </FlexWrapper>
      </OneCol>
      {!!content.length && <WorkContentControls {...{content, selectedItem, handleItemClick}} />}
    </WorkContentWrapper>
  )
}

const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`

const ArrowWrapper = styled.button`
  width: 20px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  transition: 0.25s;
  z-index: 2;
  opacity: ${({isVisible}) => (isVisible ? 1 : 0)};
  visibility: ${({isVisible}) => (isVisible ? "visible" : "hidden")};
`

const ArrowWrapperLeft = styled(ArrowWrapper)`
  left: 20px;
`

const ArrowWrapperRight = styled(ArrowWrapper)`
  right: 20px;
`

const Text = styled.div`
  max-width: 300px;
`
/*
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
`*/

const Wrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  //width: ${({hasMore}) => (hasMore ? 90 : 100)}%;
  padding-right: ${({hasMore}) => (hasMore ? 5 : 0)}%;
  position: relative;
  //transform: rotate(90deg);
`

const FlexWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  //transform: rotate(-90deg);
`

const ImageContainer = styled.div`
  position: relative;
  height: 500px;
  max-height: 70vh;
  //display: flex;
  //align-items: flex-end;
  img {
    height: 100%;
    width: auto;
  }
  //background-image: url(${({$src}) => $src});
  //background-size: contain;
  //background-repeat: no-repeat;
`

export default WorkContentOther
