// 3rd party
import React, {useContext} from "react"
import styled, {css} from "styled-components"

// constants
import {colors, sizes, transition, device} from "../../constants/css"

// context
import {NavStateContext, NavDispatchContext} from "../../context/NavContextProvider"
import {getImage} from "../../lib/dataManipulation"

// components
import FadeInImage from "../general/fadeInImage"
import Link from "../general/link"

function NavPreviewRound({post, collapsed, image, visible, isPreviewOpen}) {
  // state
  const navDispatch = useContext(NavDispatchContext)
  const navState = useContext(NavStateContext)

  // functions
  const onHover = () => !collapsed && navDispatch({type: "setPreview", post: post})
  image = getImage(image, "thumb-small")
  return (
    <Wrapper {...{isPreviewOpen}}>
      <Link uri={post.uri}>
        <StyledImg top={navState.top} isCollapsed={collapsed} visible={visible} onMouseOver={onHover} onFocus={onHover}>
          {image?.sourceUrl && <FadeInImage src={image.sourceUrl} />}
        </StyledImg>
      </Link>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  ${({isPreviewOpen}) =>
    isPreviewOpen &&
    css`
      @media ${device.laptop} {
        display: none;
        visibility: hidden;
      }
    `}
`

const StyledImg = styled.div`
  display: none;
  background-color: ${colors.black};
  @media ${device.mobileL} {
    display: block;
  }
  width: ${sizes.nav1ImgWidth}px;
  height: ${sizes.nav1ImgWidth}px;

  top: 0;
  right: ${-(sizes.nav1ImgWidth + 4)}px;
  position: absolute;

  transition: top ${transition.fast}ms, left ${transition.nav}ms, opacity ${transition.nav}ms,
    transform ${transition.nav}ms;
  visibility: ${({visible}) => (visible ? "visible" : "hidden")};
  opacity: ${({isCollapsed}) => (isCollapsed ? 0 : 1)};
  z-index: ${({isCollapsed}) => (isCollapsed ? -1 : 1)};
  transform: ${({isCollapsed}) => (isCollapsed ? "translate(-175px, -50%)" : "translate(0, -50%)")};
  border-radius: 50%;
  border: ${sizes.nav1ImgBorder}px solid ${colors.red};
  overflow: hidden;
`

export default NavPreviewRound
