// 3rd party
import React from "react"
import styled from "styled-components"

// constants
import {device, transition, sizes} from "../../../constants/css"

// util
import WorkContentThumbs from "./workContentThumbs"
import WorkContentDescription from "./workContentDescription"
import WorkContentOther from "./workContentOther"

function WorkContentItem({work, content, activeItem, setIsModalOpen, setImageNum, imageNum}) {
  let isDescription = activeItem === "description"
  let isThumbs = activeItem === "thumbs"
  let title = work?.title
  return (
    <Item>
      {isThumbs && <WorkContentThumbs {...{content, setImageNum, imageNum, setIsModalOpen, title}} />}
      {isDescription && <WorkContentDescription content={work?.description.content || content} {...{title}} />}
      {!isDescription && !isThumbs && <WorkContentOther {...{content, title, setIsModalOpen, activeItem}} />}
    </Item>
  )
}

const Item = styled.div`
  transition: ${transition.workContent}ms;
  position: fixed;
  z-index: 4;
  bottom: 0;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow-y: auto;
  left: ${sizes.left.small}px;
  right: 0;
  @media ${device.tabletM} {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
`
export default WorkContentItem
