import Link from "../../general/link"
import React from "react"
import styled from "styled-components"
import {getSnippet} from "../../../lib/utils"
import BelowMenuItem from "../styled/belowMenuItem"

const SearchResultItems = ({query, results}) => {
  return results.map((result, i) => {
    let snippet = getSnippet(result.description.content || "", query)
    return (
      <BelowMenuItem key={i} canHover={true}>
        <Link uri={result.uri} className="simple-link">
          <H3>{result.title}</H3>
          {snippet && <Div dangerouslySetInnerHTML={{__html: snippet}}></Div>}
        </Link>
      </BelowMenuItem>
    )
  })
}

const H3 = styled.h3`
  padding: 0;
`

const Div = styled.div`
  p {
    padding: 0;
    line-height: 1.25;
  }
`

export default SearchResultItems
