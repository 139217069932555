import React from "react"
import styled from "styled-components"

function Scrollable({children, handleScroll, myRef, hasMaxHeight = false}) {
  return (
    <Div
      onScroll={handleScroll}
      ref={myRef}
      onChange={handleScroll}
      hasMaxHeight={hasMaxHeight}
      className="hide-scrollbar"
    >
      {children}
    </Div>
  )
}

const Div = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  max-height: ${({hasMaxHeight}) => (hasMaxHeight ? "calc(100vh - 150px)" : "none")};
  flex-grow: 1;
`

export default Scrollable
