// 3rd party
import React, {useContext} from "react"

// context
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

// components
import SearchResultItems from "./searchResultItems"
import BelowMenuItem from "../styled/belowMenuItem"
import BelowMenuTitle from "../belowMenuTitle"

// lib
import {normalizeSearchData, search} from "../../../lib/search"

const RenderResults = ({query, isLoading}) => {
  // state
  const appState = useContext(GlobalStateContext)

  // data
  let store = normalizeSearchData(appState.navData)
  let results = query ? search(store, query) : {medium: [], project: [], work: []}
  let hasResults = !!results.medium.length || !!results.project.length || !!results.work.length

  return hasResults
    ? ["project", "medium", "work"].map(type => {
        return (
          !!results[type].length && (
            <React.Fragment key={type}>
              <BelowMenuItem hasBackground>
                <h4>browse</h4>
                <BelowMenuTitle text={type} />
              </BelowMenuItem>
              <SearchResultItems results={results[type]} query={query} />
            </React.Fragment>
          )
        )
      })
    : !isLoading && (
        <BelowMenuItem>
          <h4>no results</h4>
        </BelowMenuItem>
      )
}

export default RenderResults
