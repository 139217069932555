import React from "react"

function ExternalLink({url, children}) {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}

export default ExternalLink
