import React, {useContext} from "react"
import {GlobalStateContext} from "../../context/GlobalContextProvider"
import BelowMenu from "./belowMenu"
import BelowMenuItem from "./styled/belowMenuItem"
import BelowMenuTitle from "./belowMenuTitle"
import {useStaticQuery, graphql as gql} from "gatsby"
import Events from "./events"
import Socials from "./socials"

const About = () => {
  // state
  let appState = useContext(GlobalStateContext)
  let data = useStaticQuery(query)

  // data
  let {wp, events} = data
  let {contact, info, callToAction} = wp.themeGeneralSettings.about.about

  return appState.isInfoOpen ? (
    <BelowMenu>
      <BelowMenuItem>
        <BelowMenuTitle text="info" />
      </BelowMenuItem>
      <BelowMenuItem>{contact && <Socials contact={contact} />}</BelowMenuItem>
      <BelowMenuItem>{info && <div dangerouslySetInnerHTML={{__html: info}} />}</BelowMenuItem>
      <BelowMenuItem>{callToAction}</BelowMenuItem>
      <BelowMenuItem>{!!events.edges.length && <Events events={events.edges} />}</BelowMenuItem>
    </BelowMenu>
  ) : null
}

const query = gql`
  query About {
    events: allWpEvent {
      edges {
        node {
          title
          content
          event {
            date
          }
        }
      }
    }
    wp {
      themeGeneralSettings {
        about {
          about {
            callToAction
            info
            contact {
              facebook
              instagram
              linkedin
              phone
              vimeo
              youtube
              patreon
            }
          }
        }
      }
    }
  }
`

export default About
