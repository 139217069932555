// 3rd party
import React from "react"
import {Link as GatsbyLink} from "gatsby"
import {useLocation} from "@reach/router"

/*
import NextLink from "next/link"
import {useRouter} from "next/router"
function Link({uri, className, children}) {
  const router = useRouter()
  let isActive = router.asPath === uri || router.asPath === uri + "/"
  return (
    <NextLink href={uri}>
      <a className={className} style={isActive ? {cursor: "default"} : {}}>
        {children}
      </a>
    </NextLink>
  )
}
*/
function Link({uri, className, children}) {
  const location = useLocation()
  let isActive = location.pathname === uri || location.pathname === uri + "/"
  return (
    <GatsbyLink to={uri} className={className} style={isActive ? {cursor: "default"} : {}}>
      {children}
    </GatsbyLink>
  )
}
export default Link
