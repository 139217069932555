// 3rd party
import React, {useContext} from "react"
import styled, {css} from "styled-components"

// constants
import {colors, sizes, transition, device} from "../../constants/css"

// components
import NavSubItem from "./navSubItem"

// context
import {GlobalStateContext} from "../../context/GlobalContextProvider"
import {NavStateContext} from "../../context/NavContextProvider"

const NavSub = () => {
  // state
  const appState = useContext(GlobalStateContext)
  const navState = useContext(NavStateContext)

  // data
  let posts = navState.subPosts[appState.navType]
  let sortedPosts = posts ? [...posts] : []
  let hasNoChildren = posts.length === 1 && posts[0].node.id === navState.main[appState.navType]
  sortedPosts = sortedPosts.sort((a, b) => a.node.termOrder + 0 - (b.node.termOrder + 0))
  return (
    !!sortedPosts.length && (
      <NavSubMenu isCollapsed={appState.navIsCollapsed} top={navState.top} hideOnSmall={hasNoChildren}>
        <ul>
          {sortedPosts.map(post => {
            return <NavSubItem post={post.node} key={post.node.id} hasNoChildren={hasNoChildren} />
          })}
        </ul>
      </NavSubMenu>
    )
  )
}

const NavSubMenu = styled.div`
  z-index: 6;
  width: ${({isCollapsed}) => (!isCollapsed ? sizes.nav1Width.small + sizes.navDiamondWidth : sizes.nav1WidthSmall.small)}px;
  margin-right: ${({isCollapsed}) => (!isCollapsed ? sizes.navDiamondWidth : 0)}px;
  background-color: ${({isCollapsed}) => (isCollapsed ? "transparent" : colors.background)};
  top: ${({top}) => top - 16}px;
  position: fixed;
  left: ${({isCollapsed}) => (isCollapsed ? sizes.nav0WidthCollapsed.small : sizes.nav0Width.small)}px;
  @media ${device.tabletM} {
    width: ${({isCollapsed}) => (!isCollapsed ? sizes.nav1Width.large + sizes.navDiamondWidth : sizes.nav1WidthSmall.large)}px;
    left: ${({isCollapsed}) => (isCollapsed ? sizes.nav0WidthCollapsed.large : sizes.nav0Width.large)}px;
  }
  transition: top ${transition.fast}ms, left ${transition.nav}ms, width ${transition.nav}ms, padding ${transition.nav}ms;
  padding: ${({isCollapsed}) => (isCollapsed ? "16px 0" : "16px 10px")};
  text-align: left;

  ${({hideOnSmall}) =>
    hideOnSmall &&
    css`
      display: none;
      visibility: hidden;
      @media ${device.tabletM} {
        display: block;
        visibility: visible;
      }
    `}
`

export default NavSub
