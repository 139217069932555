import React from "react"
import styled from "styled-components"
import {colors} from "../../constants/css"

const Hamburger = ({isOpen, transformY, isVisible, onClick}) => {
  return (
    <Button
      onKeyDown={onClick}
      {...{onClick}}
      aria-label="hamburger"
      transformY={!isOpen && transformY && "translateY(40px)"}
      transformX={!isOpen && "translateX(4px)"}
    >
      <StyledBurger {...{isOpen}} isVisible={isVisible || !isOpen}>
        <div />
        <div />
        <div />
      </StyledBurger>
    </Button>
  )
}

let bottom = 15
let right = 6
let width = 20

const Button = styled.button`
  position: absolute;
  bottom: ${bottom}px;
  right: ${right}px;
  padding: 5px;
  transform: ${({transformX, transformY}) => transformX + " " + transformY};
  transition: all 0.15s;
`

const StyledBurger = styled.div`
  z-index: 101;
  opacity: ${({isVisible}) => (isVisible ? 1 : 0)};
  visibility: ${({isVisible}) => (isVisible ? "visible" : "hidden")};

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: ${width}px;
  height: 1.125rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }

  div {
    width: ${width}px;
    height: 0.1875rem;
    background: ${colors.white};
    border-radius: 10px;
    transition: all 0.1s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({isOpen}) => (isOpen ? "rotate(-45deg) scaleX(.90)" : "rotate(0)")};
      transform-origin: 100% 0%;
    }

    :nth-child(2) {
      opacity: ${({isOpen}) => (isOpen ? 0 : 1)};
      transform: ${({isOpen}) => (isOpen ? "translateX(.625rem)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({isOpen}) => (isOpen ? "rotate(45deg) scaleX(.90)" : "rotate(0)")};
      transform-origin: 100% 100%;
    }
  }
`

export default Hamburger
