// 3rd party
import React, {useContext} from "react"
import styled, {css} from "styled-components"

// constants, context
import {colors, transition} from "../../constants/css"
import {GlobalDispatchContext, GlobalStateContext} from "../../context/GlobalContextProvider"

// components
import Medium from "../icons/medium"
import Project from "../icons/project"

function MenuTop({isDragging}) {
  // state
  const appState = useContext(GlobalStateContext)
  const appDispatch = useContext(GlobalDispatchContext)

  let active = appState.navType // ????
  let handleClick = type => {
    !isDragging && appDispatch({type: "setNavType", value: type})
  }
  return (
    <TopMenu active={active}>
      <Browse active={active}>browse</Browse>
      {["medium", "project"].map((type, i) => {
        let isActive = active === type
        return (
          <MenuOpener
            key={i}
            side={i === 0 ? "left" : "right"}
            onClick={() => handleClick(type)}
            active={isActive}
            aria-label={type}
          >
            {type === "medium" && <Medium isLarge={isActive} />}
            {type === "project" && <Project isLarge={isActive} />}
          </MenuOpener>
        )
      })}
    </TopMenu>
  )
}

const MenuButton = styled.button`
  transition: all ${transition.fast}ms ease-out;
  position: absolute;
`

const Browse = styled.div`
  transition: all ${transition.fast}ms ease-out;
  position: absolute;
  cursor: default;
  top: 4px;
  padding: 1px 5px 1px 5px;
  left: ${({active}) => (active === "project" ? "41px" : "164px")};
  font-size: 0.8125rem;
  line-height: 0.91;
  letter-spacing: 1px;
  background-color: ${colors.red};
`

const TopMenu = styled.div`
  border-bottom: 3px solid ${colors.white};
  padding-top: 5px;
  position: relative;
  height: 43px;
`

const MenuOpener = styled(MenuButton)`
  cursor: pointer;
  bottom: 0;
  line-height: 1;
  svg {
    transition: all ${transition.fast}ms ease-out;
  }
  ${({active}) =>
    !active &&
    css`
      background-color: transparent;
      padding: 2px 6px;
    `}
  ${({active}) =>
    active &&
    css`
      background-color: ${colors.red};
      padding: 4px 7px 2px 7px;
    `}
  ${({side}) =>
    side === "left" &&
    css`
      left: 0;
    `}
    ${({side}) =>
    side === "right" &&
    css`
      right: 0;
    `}
`

export default MenuTop
